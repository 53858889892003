<template>
  <a-layout-sider :width="settings.leftMenuWidth" :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView" :class="{
    [$style.menu]: true,
    // [$style.white]: settings.menuColor === 'white',
    // [$style.gray]: true,
    // [$style.dark]: settings.menuColor === 'dark',
    // [$style.unfixed]: settings.isMenuUnfixed,
    // [$style.shadow]: settings.isMenuShadow,
  }" @collapse="onCollapse">
    <div :class="$style.menuOuter" :style="{
    width:
      settings.isMenuCollapsed && !settings.isMobileView
        ? '80px'
        : settings.leftMenuWidth + 'px',
    height:
      settings.isMobileView || settings.isMenuUnfixed
        ? 'calc(100% - 64px)'
        : 'calc(100% - 110px)',
  }">
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <router-link to="/">
            <img v-if="!settings.isMenuCollapsed" src="../../../assets/img/logo-biometrix.png" />
            <img v-else width="45" src="../../../assets/img/favicon.png" />
          </router-link>
        </div>
      </div>
      <perfect-scrollbar :style="{ height: 'calc(100% - 110px)', overflow: 'auto' }" class="mt-4">
        <a-menu v-model:open-keys="openKeys"
          :inline-collapsed="settings.isMobileView ? false : settings.isMenuCollapsed" :mode="'inline'"
          :selected-keys="selectedKeys" :inline-indent="15" :class="$style.navigation" @click="handleClick"
          @openChange="handleOpenChange" style="overflow-x: hidden;">
          <template v-for="(item, index) in filteredMenu">
            <template v-if="!item.roles || item.roles.includes(user.role)">
              <a-menu-item-group v-if="item.category" :key="index">
                <template #title>
                  {{ item.title }}
                </template>
              </a-menu-item-group>
              <item v-if="!item.children && !item.category && item.key !== '__logout'" :key="item.key" :menu-info="item"
                :styles="$style" />
              <sub-menu v-if="item.children" :key="item.key" :menu-info="item" :styles="$style" />
              <item v-if="item.key === '__logout'" :key="item.key" :menu-info="item" :styles="$style" />
            </template>
          </template>
        </a-menu>
      </perfect-scrollbar>
      <!-- <div class="d-flex align-items-center justify-content-center">
        <a-button style="background-color: #2A458C;border: 1px solid white  ;"><img src="../../../assets/img/logout.png"
            class="mr-2" /><span class="text-white">Logout</span></a-button>
      </div> -->
      <div class="d-flex justify-content-center align-items-center" style="margin: -10px 0"
        v-if="!settings.isMenuCollapsed">
        <a-button :class="$style['custom-button-logout']" class="w-100"><i class="fe fe-log-out mr-2" />
          Logout</a-button>
      </div>
      <div class="d-flex justify-content-center align-items-center" style="margin: -10px 0" v-else>
        <a-button :class="$style['custom-button-logout']" class="w-100"><i class="fe fe-log-out" /></a-button>
      </div>
    </div>
  </a-layout-sider>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import find from 'lodash/find'
import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'MenuLeft',
  components: { SubMenu, Item },
  setup() {
    const store = useStore()
    const route = useRoute()
    let menuData = computed(() => getMenuData)
    let filteredMenu = ref([])
    let selectedKeys = ref([])
    let openKeys = ref([])
    let settings = computed(() => store.getters.settings)
    let isMenuCollapsed = computed(() => store.getters.settings.isMenuCollapsed)
    let user = computed(() => store.getters['user/user'])
    let pathname = computed(() => route.name)

    const onCollapse = (collapsed, type) => {
      const value = !settings.value.isMenuCollapsed
      store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    }

    const handleClick = e => {
      if (e.key === 'settings') {
        store.commit('CHANGE_SETTING', {
          setting: 'isSettingsOpen',
          value: true,
        })
        return
      }
      selectedKeys.value = [e.key]
    }

    const handleOpenChange = openKeys => {
      openKeys.value = openKeys
    }

    const setSelectedKeys = () => {
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData.value.concat(), 'children'), [
        'title',
        pathname.value,
      ])
      console.log('inidia', pathname.value)
      selectedKeys.value = selectedItem ? [selectedItem.key] : []
    }

    onMounted(() => {
      console.log('semoga masuk kesini')
      // console.log(menuData.value)
      const storedUserData = localStorage.getItem('userData')
      // Memeriksa apakah data tersedia di localStorage
      if (storedUserData) {
        // Mengonversi data dari string JSON menjadi objek JavaScript
        const userData = JSON.parse(storedUserData)

        // Menggunakan kembali data
        console.log('User Role:', userData.user.role)
        // if (userData.user.role === 'dcOwner') {
        //   const menuFilter = menuData.value.filter(e => e.title === 'Dashboard' || e.title === 'SITE MANAGER' || e.title === 'Rollout' || e.title === 'ATS' || e.title === 'Manajemen Akun' ||
        //     e.title === 'Entrance System' || e.title === 'Change' ||
        //     e.title === 'Change Request' || e.title === 'Assets')
        //   filteredMenu.value = menuFilter
        // }
        // const menuFilter = menuData.value.filter(
        //   e =>
        //     e.title === 'Dashboard' ||
        //     e.title === 'Profil' ||
        //     e.title === 'Hasil Test' ||
        //     e.title === 'Tambah Test',
        // )

        const menuFilter = menuData.value.filter(
          e =>
            e.title === 'Assets' || e.title === 'Users' || e.title === 'Collection Points' || e.title === 'Profil',
        )
        filteredMenu.value = menuFilter
      } else {
        console.log('Data not found in localStorage')
      }
      setSelectedKeys()
    })

    watch(pathname, () => setSelectedKeys())
    watch(isMenuCollapsed, () => (openKeys.value = []))

    return {
      menuData,
      selectedKeys,
      openKeys,
      settings,
      user,
      onCollapse,
      handleClick,
      handleOpenChange,
      filteredMenu,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';

.custom-button-logout {
  background-color: #2a458c !important;
  color: $white !important;
  border: none !important;
  border-radius: 0 !important;

  &:hover {
    background-color: $gray-2 !important;
    color: #2a458c !important;
  }
}
</style>
